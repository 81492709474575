<template>
  <div>
    <portal to="subheader_portal">
      <router-link :to="{ name: 'person_create' }">
        <b-button variant="success"
          ><b-icon icon="plus" aria-hidden="true"></b-icon> Nuevo</b-button
        >
      </router-link>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card>
          <b-card-text>
            <b-table
              :items="items"
              :fields="fields"
              head-variant="light"
              caption-top
            >
              <template #cell(is_active)="data">
                <toggle-button
                  v-model="data.item.is_active"
                  :value="data.item.is_active"
                  :labels="{ checked: 'ON', unchecked: 'OFF' }"
                />
              </template>
              <template #cell(full_name)="data">
                {{ data.item.first_name }} {{ data.item.last_name }}
                {{ data.item.last_name_2 }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mb-2 mr-2"
                  variant="primary"
                  @click="editPerson(data.item)"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>
                <b-button
                  size="sm"
                  class="mb-2 mr-2"
                  variant="danger"
                  @click="deactivatePerson(data.item)"
                  title="Desactivar persona"
                >
                  <b-icon icon="archive" aria-hidden="true"></b-icon>
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import ApiService from "../../../core/services/api.service";

export default {
  data() {
    return {
      fields: [
        { key: "doc_number", label: "DNI/RUC" },
        { key: "full_name", label: "Nombres y apellidos/Razón social" },
        { key: "is_active", label: "" },
        { key: "actions", label: "" },
      ],
      items: [],
      perPage: 10,
      currentPage: 1,
      rows: null,
    };
  },
  mounted() {
    this.getPersons();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Persona", route: "/person" },
    ]);
  },
  methods: {
    deactivatePerson(person) {
      ApiService.delete(`core/person/${person.id}/`);
    },
    editPerson(person) {
      this.$router.push({ name: "person_edit", params: { id: person.id } });
    },
    getPersons() {
      ApiService.get(
        "core/person",
        `?page=${this.currentPage}&page_size=${this.perPage}`
      ).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.count;
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getPersons();
    },
  },
};
</script>
